import { NavigationItemProps } from '../Navigation/Item';
import { IconWithBlip } from './WithBlip';
import { useSetting } from '@/contexts/SettingsContext';

export const IconNews: NavigationItemProps['icon'] = (props) => {
  const [mostRecentNewsId, setMostRecentNewsId] = useSetting('mostRecentNewsId');

  return (
    <IconWithBlip
      name="Bell"
      showBlip={Boolean(mostRecentNewsId) && mostRecentNewsId !== props.recentNews[0]?.id}
      onClick={() => {
        setMostRecentNewsId(props.recentNews[0].id);
      }}
    />
  );
};
