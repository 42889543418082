import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useSetting } from '@/contexts/SettingsContext';

type LearningModeContext = [boolean, (value: boolean) => void];

const LearningModeContext = createContext<LearningModeContext | null>(null);

export const LearningModeContextProvider: FC = ({ children }) => {
  const [learningMode, setLearningMode] = useSetting('learningMode');
  const [learningModeEnabled, setLearningModeEnabled] = useState(learningMode);

  useEffect(() => {
    setLearningModeEnabled(learningMode);
  }, [learningMode]);

  return (
    <LearningModeContext.Provider
      value={[
        learningModeEnabled,
        (val: boolean) => {
          setLearningModeEnabled(val);
          setLearningMode(val);
        },
      ]}
    >
      {children}
    </LearningModeContext.Provider>
  );
};

export const useLearningMode = () => {
  const context = useContext(LearningModeContext);
  if (!context) throw new Error('LearningModeContext must be used with LearningModeContextProvider!');
  return context;
};
