import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';

interface Settings {
  noticeDismissed: boolean;
  mostRecentNewsId: string;
  textSize: number;
  whitepointReduction: boolean;
  grayscale: boolean;
  learningMode: boolean;
}

const SettingsContext = createContext<[Settings, (newSettings: Settings) => void] | null>(null);

// These are applied server-side and before settings are loaded
const serverSideDefaults: Settings = {
  noticeDismissed: true,
  mostRecentNewsId: '',
  textSize: 0,
  whitepointReduction: false,
  grayscale: false,
  learningMode: false,
};

// These are applied after settings were loaded
const clientSideDefaults: Settings = {
  ...serverSideDefaults,
  noticeDismissed: false,
  mostRecentNewsId: '__none__',
};

export const SettingsContextProvider: FC = ({ children }) => {
  const [settings, setSettings] = useState(serverSideDefaults);

  useEffect(() => {
    const value = localStorage.getItem('settings');
    if (value) {
      try {
        setSettings(JSON.parse(value));
      } catch (e) {
        localStorage.removeItem('settings');
      }
    } else {
      setSettings(clientSideDefaults);
    }
  }, []);

  function clientSetSettings(newSettings: Settings) {
    localStorage.setItem('settings', JSON.stringify(newSettings));
    setSettings(newSettings);
  }

  return <SettingsContext.Provider value={[settings, clientSetSettings]}>{children}</SettingsContext.Provider>;
};

export function useSetting<K extends keyof Settings>(key: K): [Settings[K], (value?: Settings[K]) => void] {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('SettingsContext must be used with SettingsContextProvider!');
  const [settings, setSettings] = context;

  function setValue(value?: Settings[K]) {
    setSettings({ ...settings, [`${key}`]: value });
  }

  return [settings[key], setValue];
}

export default useSetting;
