import { Link, useLanguage } from '@/contexts/LanguageContext';
import { Icon } from '../../../Icon';
import { NavigationProps } from '../..';
import { NavigationItemProps } from '..';

export const NavigationItemContentNewsSidebar = ({ recentNews, closeNav }: NavigationProps & NavigationItemProps) => {
  const { t, lang } = useLanguage();

  return (
    <div className="flex flex-col gap-12 my-auto">
      {recentNews.map((news) => {
        return (
          <Link href={`[lang]/news/${news.slug}`} key={news.id}>
            <a onKeyDown={closeNav} role="button" tabIndex={0} onClick={closeNav}>
              <p className="mb-4 text-lg font-bold leading-relaxed">{news[lang]?.title}</p>

              <p className="flex items-center text-gray-700">
                {t.general.readMore} <Icon name="Right Arrow" className="ml-2 opacity-75" />
              </p>
            </a>
          </Link>
        );
      })}
    </div>
  );
};
