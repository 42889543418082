import { useLanguage } from '@/contexts/LanguageContext';
import { useEffect } from 'react';
import { Toggle } from '../../../Toggle';
import { useSetting } from '@/contexts/SettingsContext';
import { useLearningMode } from '@/contexts/LearningModeContext/LearningModeContext';
import { buttonStyles } from '@/components/ui/Button';
import cn from 'classnames';
import { Icon } from '@/components/ui/Icon';

type TextSize = '100%' | '116%' | '125%';

export const NavigationItemContentAccessibilitySettings = () => {
  const { t } = useLanguage();
  const [textSize, setTextSize] = useSetting('textSize');
  const [whitepointReduction, setWhitepointReduction] = useSetting('whitepointReduction');
  const [grayscale, setGrayscale] = useSetting('grayscale');
  const [learningModeEnabled, setLearningModeEnabled] = useLearningMode();

  const changeTextSize = (position) => {
    const sizes: TextSize[] = ['100%', '116%', '125%'];
    (document.body.style as any).zoom = sizes[position];
    setTextSize(position);
  };

  useEffect(() => {
    const el = document.getElementById('whitepointreduction');
    if (!el) return;

    if (whitepointReduction) {
      el.classList.remove('hidden');
    } else {
      el.classList.add('hidden');
    }
  }, [whitepointReduction]);

  useEffect(() => {
    const el = document.documentElement;
    if (!el) return;

    if (grayscale) {
      el.classList.add('grayscale');
    } else {
      el.classList.remove('grayscale');
    }
  }, [grayscale]);

  return (
    <div className="flex flex-col self-stretch justify-center lg:flex-1">
      <div className="p-1 border-2 border-black border-opacity-0 focus-within:border-opacity-100">
        <label className="font-bold flex flex-col gap-8" htmlFor="textSize">
          {t.general.settings.fontSize}
          <input
            type="range"
            name="textSize"
            min="0"
            max="2"
            step="1"
            defaultValue={textSize}
            onChange={(e) => changeTextSize(e.target.value)}
          />
        </label>
      </div>

      <div className="flex items-baseline justify-between mb-6 font-bold">
        <span className="text-xs">A</span>
        <span className="text-base">A</span>
        <span className="text-xl">A</span>
      </div>

      {/* <Toggle
        label={t.general.settings.blueBlindness}
        name="blueBlindness"
        className="flex items-center justify-between md:text-xl lg:text-base"
      />

      <Toggle
        label={t.general.settings.redGreenBlindness}
        name="redGreenBlindness"
        className="flex items-center justify-between md:text-xl lg:text-base"
      /> */}

      <Toggle
        label={t.general.settings.whitepointReduction}
        name="whitepointReductionSetting"
        className="flex items-center justify-between md:text-xl lg:text-base"
        onChange={(value) => setWhitepointReduction(value)}
        defaultValue={whitepointReduction}
      />

      <Toggle
        label={t.general.settings.grayscaleOnly}
        name="grayscaleOnly"
        className="flex items-center justify-between md:text-xl lg:text-base"
        onChange={(value) => setGrayscale(value)}
        defaultValue={grayscale}
      />

      {'learningMode' in t.general.settings && (
        <Toggle
          label={t.general.settings.learningMode}
          name="learningModeEnabled"
          className="flex items-center justify-between md:text-xl lg:text-base"
          onChange={(value) => setLearningModeEnabled(value)}
          defaultValue={learningModeEnabled}
        />
      )}

      {/* <Toggle label={t.general.settings.darkmode} name="darkmode" className="flex items-center justify-between md:text-xl lg:text-base" /> */}
      <section className="px-1">
        <div className="mt-6 border-2 border-black border-opacity-0 focus-within:border-opacity-100">
          <span className="font-bold flex flex-col gap-8 text-lg">{t.general.settings.easyReadingTitle}</span>
        </div>
        <p className="text-lg mb-5 pl-[0.1rem]">{t.general.settings.easyReadingDescription}</p>
        <div className="grid md:grid-cols-2 w-full items-center gap-4">
          <a
            href="https://chromewebstore.google.com/detail/easy-reading/dgbgeoiafhipdcmfjalfamhdpiohjdnc?pli=1"
            target="_blank"
            rel="noopener noreferrer"
            className={cn(buttonStyles, 'flex font-bold justify-between items-center')}
            role="button"
          >
            {t.general.settings.chromeExtension}
            <Icon className="w-5 h-5" name={'Download'} />
          </a>

          <a
            href="https://addons.mozilla.org/de/firefox/addon/easy-reading-for-firefox/"
            target="_blank"
            rel="noopener noreferrer"
            className={cn(buttonStyles, 'flex font-bold justify-between items-center')}
            role="button"
          >
            {t.general.settings.firefoxExtension}
            <Icon className="w-5 h-5" name={'Download'} />
          </a>
        </div>
      </section>
    </div>
  );
};
